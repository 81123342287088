// Custom variables :: Colors
//
// -> bk : black;
// -> gy : grey;
// -> wh : white;
// -> pu : purple;
// -> bl : blue;
// -> br : brown;
// -> or : orange;
// -> gn : green;
// -> pk : pink;
$wh-a: #FFFFFF;
$bk-a: #212121;
$gy-a: #fafafa;
$gy-b: #CCC;
$gn-a: #008D36;
$gn-b: #7db832;

.c-wh-a {
  color: $wh-a;
}

.c-bk-a {
  color: $bk-a;
}

.c-gy-a {
  color: $gy-a;
}

.c-gy-b {
  color: $gy-b;
}

.c-gn-a {
  color: $gn-a;
}

.c-gn-b {
  color: $gn-b;
}

.bg-wh-a {
  background-color: $wh-a;
}

.bg-bk-a {
  background-color: $bk-a;
}

.bg-gy-a {
  background-color: $gy-a;
}

.bg-gy-b {
  background-color: $gy-b;
}

.bg-gn-a {
  background-color: $gn-a;
}

.bg-gn-b {
  background-color: $gn-b;
}

.bg-radient-green {
  background: #008d36;
  background: -moz-linear-gradient(top, #008d36 0%, #7db832 100%);
  background: -webkit-linear-gradient(top, #008d36 0%, #7db832 100%);
  background: linear-gradient(to bottom, #008d36 0%, #7db832 100%);
}