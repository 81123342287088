// Body
body {
  overflow: hidden;
  overflow-y: auto;
}

body,
html {
  width: 100%;
  background-color: #fff;
}

html {
  overflow-x: hidden;
}

// Utils :: Image renderer optimize

.renderer-optmimize {
  image-rendering: -moz-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

// Utils :: Hide play button on video iphone

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

// Components :: Magnific popup

.mfp-close {
  width: 30px !important;
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 0;
  top: 5px;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
  display: block;
  background-image: url("../images/icons/sprite-popup.png") !important;
  background-position: -60px 0 !important;
  text-indent: 999em;
  overflow: hidden !important;
  transition: all 0.2s ease-in-out;
  background-size: 150px !important;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 30px;
  height: 60px;
  display: block;
  background-image: url("../images/icons/sprite-popup.png") !important;
  transition: all 0.2s ease-in-out;
  background-size: 150px !important;
}

.mfp-arrow-left {
  left: 20px;
  background-position: 0 0 !important;
}

.mfp-arrow-right {
  right: 20px;
  background-position: -30px 0 !important;
}

.mfp-arrow:after,
.mfp-arrow:before {
  display: none !important;
}

.mfp-zoom-out .mfp-with-anim {
  opacity: 0;
  transform: scale(1.3);
  transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-bg {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
  transform: scale(1.3);
  opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
  opacity: 0;
}

// Components :: Slick

.slick-slide {
  outline: none !important;
}

.slick-vertical .slick-slide {
  border: none !important;
}

.slick-dots {
  margin-top: px(15);
  text-align: center;

  li {
    display: inline-block;
    vertical-align: top;
    margin: 0 6px;
  }

  button {
    width: 10px;
    height: 10px;
    margin-right: 2px;
    border-radius: 100%;
    background-color: $gy-b;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    text-indent: -999em;
  }

  .slick-active button {
    background-color: $gn-a;
  }

  button:focus,
  button:hover {
    background-color: $gn-a;
  }
}

// Components :: WOW

.fadeIn,
.fadeInDown,
.fadeInLeft,
.fadeInRight,
.fadeInUp {
  visibility: hidden;
}

// Function :: Equal Col
@include media-breakpoint-down(md) {
  .equal-col-item {
    height: auto !important;
  }
}

// Component :: Google map

.map-wrapper {
  position: relative;
  height: 300px;
  background-color: #e5e3df;

  .map-canvas {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

// Component :: Parallax

.parallax-mirror {
  z-index: 0 !important;
}

.parallax-wp {
  @extend .clearfix;
  overflow: hidden;
  position: relative;
  height: 300px;
  background: transparent;
}

.home-scroll-trigger {
  text-align: center;
  font-size: 11px;
  z-index: 10;

  >* {
    display: block;
    margin: 0 auto;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

#slick-home-top-wp {
  position: relative;

  @include media-breakpoint-down(md) {
    margin-top: 15px;
  }

  .title {
    font-size: 46px;
    line-height: 1.2em;
    text-transform: uppercase;

    @include media-breakpoint-down(lg) {
      font-size: 40px;
    }

    @include media-breakpoint-down(md) {
      font-size: 36px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 32px;
    }

  }

  .slick-dots {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;

    @include media-breakpoint-down(sm) {
      margin-top: 20px;
      position: relative;
      bottom: auto;
    }
  }

}

#slick-home-top {
  position: relative;

  .slick-slide .img-fluid {
    opacity: 0;
    transform: translateY(-40px) scale(.7);
    transition: all 0.3s ease-in-out 0.5s;
  }

  .slick-slide.animation .img-fluid {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  .item {
    min-height: 500px;


    .row {
      padding-bottom: 100px;
      height: 100%;

      @include media-breakpoint-down(sm) {
        padding-bottom: 0;
      }
    }

    @include media-breakpoint-down(md) {
      min-height: 0;
      height: auto !important;
    }
  }

}


.home-selection-item {
  .link {
    display: block;
    text-align: center;

    .img-wp {
      padding: 15px;
      background-color: #f7f7f7;
      transition: all .2s ease-in-out;
    }

    .title {
      margin-top: 10px;
      font-size: 13px;
      font-weight: 500;
    }

    &:hover {
      .img-wp {
        background-color: #d9eee1;
      }

      .title {
        color: $gn-a;
      }
    }
  }
}

#slick-home-vertical-wp {

  .slick-slide .img-fluid {
    opacity: 0;
    transform: translateY(-40px) scale(.85);
    transition: all 0.1s ease-in-out 0.5s;
  }

  .slick-slide .text-wp {
    opacity: 0;
  }

  .slick-slide.animation .img-fluid {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  .slick-slide.animation .text-wp {
    opacity: 1;
  }

  .item {
    height: 600px;
    color: $wh-a;


    @include media-breakpoint-down(sm) {
      padding: 50px 0;
      height: auto;

      .btn-a {
        font-size: 11px;
      }
    }

    .text-wp {
      transition: all .4s ease-in-out;
    }

    &.bg-bk-a {
      .btn-a {
        color: $bk-a;

        &:after {
          @extend .arrow-btn-black;
        }

      }
    }

    .container,
    .row {
      height: 100%;
    }
  }

  .slick-dots {
    position: absolute;
    top: 50%;
    right: 30px;
    margin-top: -40px;

    .slick-active button {
      background-color: shade($gy-a, 10%);
    }

    li {
      display: block;
      position: relative;

      button {
        background: $wh-a;
      }

      &:before {
        position: absolute;
        font-size: 13px;
        color: $wh-a;
        right: 25px;
        top: 2px;
        opacity: 0;
        white-space: nowrap;
        transition: all .2s ease-in-out;
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      position: absolute;
      margin-top: 0;
      top: auto;
      bottom: -30px;
      right: auto;
      width: 100%;
      text-align: center;

      .slick-active button {
        background-color: $gn-a;
      }

      li {
        display: inline-block;

        &:before {
          display: none;
        }

        button {
          background: shade($gy-a, 10%);
        }

        .slick-active button {
          background-color: $gn-a;
        }
      }
    }

    li:nth-child(1) {
      &:before {
        content: "Agro Alimentaire";
      }
    }

    li:nth-child(2) {

      &:before {
        content: "Industrie";
      }
    }

    li:nth-child(3) {

      &:before {
        content: "Santé";
      }
    }

    li:nth-child(4) {

      &:before {
        content: "Bâtiment";
      }
    }
  }
}

.product-item {
  height: 100%;

  .link {
    padding: 30px;
    display: block;
    background-color: $gy-a;
    transition: all .2s ease-in-out;
    text-align: center;
    height: 100%;
  }

  .img-wp {
    display: block;

    img {
      transition: all .2s ease-in-out;
    }
  }

  .title {
    font-size: 13px;
    text-transform: uppercase;
    color: $gn-a;
    line-height: 1.3em;
    font-weight: 600;
  }

  .ref {
    font-style: italic;
    display: block;
    font-size: 12px;
    margin-top: 5px;
    color: shade($gy-b, 20%);
  }

  .link {
    &:hover {
      background-color: #d9eee1;

      img {
        transform: scale(.9);
      }
    }
  }
}

.btn-filter {
  &:after {
    transform: rotate(90deg) !important;
  }

  &.open {
    &:after {
      transform: rotate(-90deg) !important;
    }
  }
}

.filter-wp {
  position: relative;

  .title {
    font-weight: 300;
    font-size: 24px;
  }

  .content {
    padding: 10px;
    border-top: 1px solid tint($gy-b, 50%);
    box-shadow: inset 0 2px 8px -6px rgba(0, 0, 0, .2);
  }

  .item {
    background-color: tint($gy-a, 60%);
    color: $bk-a;
    margin-top: 20px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 10px;
    }
  }

  .title-sub {
    font-weight: 300;
    font-size: 16px;
  }

  .btn-wp {
    background-color: $gy-a;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    align-items: center;
    text-align: left;
    width: 100%;
    font-weight: 600;
    color: $gn-a;
    text-transform: uppercase;
    font-size: 12px;

    &.open {
      .material-icons {
        transform: rotate(180deg);
      }
    }
  }
}


.cat-article {
  display: inline-block;
  border: 1px solid $gn-a;
  font-size: px(11);
  padding: px(5) px(10) px(4);
  line-height: 1em;
  color: $gn-a;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 500;

  &.small {
    padding: px(3) px(7) px(2);
    font-size: px(9);
  }
}

.wysiwyg-wp {
  >*:first-child {
    margin-top: 0 !important;
  }

  >*:last-child {
    margin-bottom: 0 !important;
  }

  h2 {
    font-size: 30px;
    font-weight: 700;
    color: $gn-a;
  }

  h3 {
    font-size: 26px;
    font-weight: 700;
    color: $gn-a;
  }

  .img-inline {
    margin: 25px 10px;
    display: inline-block;
  }

  .headline {
    font-size: 17px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .highlight-wp {
    margin-bottom: 0;
    background-color: shade($gy-a, 1%);
    color: $gn-a;
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
    padding: 30px;
    margin: 50px auto;
    width: 75%;
    border-radius: 20px;
    display: block;
  }

  ol,
  ul {
    padding-left: 30px;
  }

  ol li,
  ul li {
    display: block;
    position: relative;
    padding-left: 22px;
    margin-bottom: 10px;
  }

  ul li {
    font-weight: 400;
    font-size: 16px;
  }

  ul li:before {
    content: "";
    @include position(absolute, 9px null null 3px);
    width: 6px;
    height: 6px;
    background-color: $gn-a;
    border-radius: 100%;
  }

  ol {
    counter-reset: ol-counter;
  }

  ol li {
    position: relative;
    display: block;
    line-height: 1.4em;
    font-size: 16px;
    font-weight: 400;
  }

  ol li:before {
    content: counter(ol-counter)".";
    color: $gn-a;
    counter-increment: ol-counter;
    font-size: px(14);
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 0;
  }

  ol li:last-child {
    margin-bottom: 0;
  }

  h2,
  h3,
  ol,
  ul {
    margin: 30px 0 35px;
  }

  a {
    color: $gn-a;
    text-decoration: underline;
  }

  .btn-wp {
    margin: 30px 0;
  }

  .btn-wp a {
    @extend .btn-a;
    @extend .green;
    text-decoration: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }


  img.img-left {
    float: left;
    margin: 5px 30px 15px 0;
  }

  img.img-right {
    float: right;
    margin: 5px 0 15px 30px;
  }

  img.img-center {
    margin: 50px auto;
    display: block;
  }

  table {
    @extend .table;
    @extend .table-striped;
    @extend .table-bordered;
  }

  @include media-breakpoint-down(sm) {
    h2 {
      font-size: 25px;
    }

    h3 {
      font-size: 21px;
    }

    .headline {
      font-size: 16px;
    }

    .highlight-wp {
      width: 100%;
    }

    img.img-left,
    img.img-right {
      float: none;
      margin: 30px 0 !important;
      display: block;
    }

    .btn-wp a {
      padding-right: px(15);
    }
  }

  @include media-breakpoint-down(xs) {
    h2 {
      font-size: 22px;
    }

    h3 {
      font-size: 19px;
    }

    .headline {
      font-size: 15px;
    }

    li,
    ol li:before,
    p {
      font-size: 14px !important;
    }

    .highlight-wp {
      width: 100%;
    }

    ol,
    ul {
      padding-left: 0;
    }

    img,
    img.img-center {
      float: none;
      margin: 20px 0 !important;
      display: block;
    }

    h2,
    h3,
    ol,
    ul {
      margin: 20px 0;
    }

    .highlight-wp {
      margin: 20px 0;
      padding: 20px;
    }
  }
}

@include media-breakpoint-down(xs) {
  body .wysiwyg-wp>*:first-child {
    margin-top: 0 !important;
  }

  body .wysiwyg-wp>*:last-child {
    margin-bottom: 0 !important;
  }
}

#modal-content {
  .wysiwyg-wp h2 {
    font-size: 20px;
  }


  .wysiwyg-wp h3 {
    font-size: 17px;
  }

  .wysiwyg-wp h2,
  .wysiwyg-wp h3,
  .wysiwyg-wp ol,
  .wysiwyg-wp ul {
    margin: 20px 0;
  }

  .wysiwyg-wp p {
    font-size: 14px;
  }

  @include media-breakpoint-down(sm) {
    .wysiwyg-wp h2 {
      font-size: 18px;
    }

    .wysiwyg-wp h3 {
      font-size: 15px;
    }
  }
}

.title-brand {
  font-weight: 400;
  font-size: 12px;
  color: shade($gy-b, 20%);
  margin-bottom: 0;
  text-transform: uppercase;
}

.title-product {
  font-weight: 600;
  color: $gn-a;
  margin-top: 7.5px;
  line-height: 1.1em;
  font-size: 24px;
}

.title-ref {
  display: inline-block;
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1em;
  margin-top: 7.5px;
  background-color: shade($gy-a, 5%);
}

.desc {
  line-height: 1.4em;
  color: $bk-a;
  font-weight: 100;
  font-size: px(15);
}

.btn-back {
  color: shade($gy-b, 50%);
  display: flex;
  position: relative;
  align-items: center;
  text-transform: uppercase;

  .txt {
    font-size: 11px;
    line-height: 1em;
    padding-left: 5px;
    text-transform: uppercase;
  }

  .material-icons {
    font-size: 16px;
  }
}