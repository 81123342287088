// Structure 

#ie-fallback {
  display: none;
  position: relative;
  width: 100%;
  height: 1000px;
  background: url("../images/illustrations/ie-bg.jpg") no-repeat 50% 0 #000;
  overflow: hidden;
}

#ie-box {
  display: block;
  position: absolute;
  top: 100px;
  left: 50%;
  width: 453px;
  height: 357px;
  margin-left: -226px;
  background: url("../images/illustrations/ie-box.png") no-repeat 0 0;
}
// Show Old IE
.old-ie .ie-wp {
  display: none !important;
}

.old-ie body {
  background-color: #5e5e5e;
  padding-top: 0 !important;
}

.old-ie #ie-fallback {
  display: block;
}

.old-ie body #modal-loader {
  display: none !important;
}
