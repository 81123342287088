.menu-open {
  #nav-main {
    margin-top: 10px;
  }

  #header-main {
    padding-bottom: 0;
  }
}

#header-top {
  @extend .bg-radient-green;
  padding: 10px 0;
  text-align: right;

  &.search-open {
    .search-wp {
      display: block;
    }

    .list {
      opacity: 0;
    }
  }

  .container {
    position: relative;
  }

  .search-wp {
    display: none;
    position: absolute;
    top: -3px;
    right: 15px;
    z-index: 2;

    >button {
      transition: all .2s ease-in-out;

      &:hover {
        transform: scale(.9);
        opacity: .8;
      }
    }



    input {
      border: 0;
      outline: 0;
      width: 300px;
      height: 28px;
      border-radius: 3px;
      padding: 5px 10px;
      font-size: 12px;
      color: shade($gy-b, 50%);
    }
  }

  .list {

    li {
      display: inline-block;
      margin-left: 20px;

      >* {
        display: block;
        transition: all .2s ease-in-out;

        &:hover {
          transform: scale(.9);
          opacity: .8;
        }
      }
    }
  }
}

#header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 45px;

  .logo {
    display: block;
  }

  @include media-breakpoint-down(lg) {
    padding: 15px 0;
  }

  @include media-breakpoint-down(md) {
    .logo {
      width: 90px;
      margin: 0 auto 20px auto;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 10px 0;

    .logo {
      width: 50px;
      height: 50px;
      overflow: hidden;
      margin: 0;
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
  }

}

#nav-main {
  padding-left: 30px;
  display: flex;
  justify-content: end;
  width: 100%;

  @include media-breakpoint-down(md) {
    padding-left: 0px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0;
  }

  .item {
    &:not(:first-child) {
      margin-left: 15px;
    }

    @include media-breakpoint-down(md) {
      &:not(:first-child) {
        margin-left: 7.5px;
      }
    }

    @include media-breakpoint-down(sm) {
      margin: 0 !important;
    }

    .link {
      display: block;
      border: 2px solid $gn-a;
      padding: 10px 10px 8px;
      font-weight: 500;
      text-transform: uppercase;
      position: relative;
      font-size: 14.5px;
      border-color: $wh-a;
      text-align: center;
      transition: all .2s ease-in-out;

      @include media-breakpoint-down(sm) {
        border: 0;
        padding: 10px;
      }

      &:hover,
      &.active {
        border-color: $gn-a;
        color: $gn-a;
      }

      @include media-breakpoint-down(lg) {
        font-size: 13.5px;
      }

      @include media-breakpoint-down(md) {
        font-size: 13px;
        height: 100%;
        display: flex;
        align-items: center;
        line-height: 1.3em;
      }

    }
  }

  .item-sub {
    &:not(:last-child) {
      margin-bottom: 6px;
    }

    .link-sub {
      position: relative;
      padding: 0 0 0 10px;
      display: block;
      line-height: 1.2em;
      transition: all .2s ease-in-out;

      &:hover {
        color: $gn-a;
      }

      &:before {
        content: "-";
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    display: none;
    background-color: shade($gy-a, 5%);
    width: calc(100% + 30px);
    position: relative;
    padding: 15px;
    left: -15px;
  }

}

// Navigation :: Force Display on large device
@include media-breakpoint-up(sm) {
  #nav-main {
    display: flex !important;
  }

  #nav-mobile-trigger {
    display: none;
  }
}

// Header :: Structure

#nav-mobile-trigger {
  position: absolute;
  background-color: $gn-a;
  padding: 10px;

  @include media-breakpoint-down(xs) {
    left: auto;
    top: 58px;
    right: 15px;
  }
}

#header {
  top: 0;
  width: 100%;
  position: relative;
  z-index: 100;
  width: 100%;
  padding: 0;
  background-color: $wh-a;
}

// Navigation :: Main



// Navigation :: Sub

.nav-sub {
  display: none;
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  opacity: 1;

  @include media-breakpoint-down(xs) {
    background-color: $gy-a;
    padding: 10px 15px 10px 25px;
    width: calc(100% + 30px);
    left: -15px;
  }
}

li[data-navsub].focus>.nav-sub {
  top: 52px;

  @include media-breakpoint-down(lg) {
    top: 43px;
  }

  @include media-breakpoint-down(sm) {
    top: 50px;
  }

}

li[data-navsub] {
  position: relative;
}

@include media-breakpoint-up(sm) {
  li[data-navsub].focus>.nav-sub {
    visibility: visible;
    display: block;
    padding: 20px;
    height: auto;
    opacity: 1;
  }

  .nav-sub {
    display: none;
    position: absolute;
    left: 0;
    z-index: 99;
    overflow: hidden;
    height: 0;
    width: 200px;
    padding: 0;
    opacity: 0;
    background-color: $gy-a;
    transition: all 0.1 ease-in-out;
  }
}

#header-page {
  background: #7db832;
  background: -moz-linear-gradient(top, #008d36 0%, #7db832 100%);
  background: -webkit-linear-gradient(top, #008d36 0%, #7db832 100%);
  background: linear-gradient(to bottom, #008d36 0%, #7db832 100%);
  background-position: center bottom;
  background-size: cover;
  padding: 65px 0;
  color: $wh-a;
  text-align: center;

  .title {
    font-weight: 400;
    font-size: 42px;
    text-shadow: 0px 13px 21px rgba(0, 0, 0, .2);
  }

  @include media-breakpoint-down(lg) {
    padding: 50px 0;

    .title {
      font-size: 36px;
    }
  }

  @include media-breakpoint-down(md) {
    .title {
      font-size: 32px;
    }

  }

  @include media-breakpoint-down(sm) {
    padding: 40px 0;

    .title {
      font-size: 30px;
    }

  }
}