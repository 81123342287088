#footer {
  background: #008d36;
  background: -moz-linear-gradient(top, #008d36 0%, #7db832 100%);
  background: -webkit-linear-gradient(top, #008d36 0%, #7db832 100%);
  color: $wh-a;

  .text-bold {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2em;
  }

  .title {
    font-weight: 600;
    font-size: 14px;
  }

  .list-bottom {
    display: flex;
    justify-content: center;

    li {
      margin: 0 20px;

      @include media-breakpoint-down(md) {
        margin: 0 10px;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      display: block;
      text-align: center;
    }
  }

  @include media-breakpoint-down(lg) {}

  @include media-breakpoint-down(md) {}

  @include media-breakpoint-down(sm) {}
}