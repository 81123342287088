// Sprite :: Structure

.sprite {
  display: inline-block;
  background-image: url("../images/icons/sprite.png");
  background-repeat: no-repeat;
  vertical-align: text-top;
}

.sprite {
  &.back-top {
    width: 40px;
    height: 40px;
    background-position: 0 0;
  }

  &.arrow-scroll {
    width: 10px;
    height: 30px;
    background-position: -50px 0;
  }

  &.arrow-btn-white,
  &.arrow-btn-green,
  &.arrow-btn-black {
    width: 20px;
    height: 10px;
  }

  &.arrow-btn-white {
    background-position: -60px 0;
  }

  &.arrow-btn-green {
    background-position: -60px -10px;
  }

  &.arrow-btn-black {
    background-position: -60px -20px;
  }

  &.icon-phone,
  &.icon-facebook,
  &.icon-search,
  &.icon-close {
    width: 20px;
    height: 20px;
  }

  &.icon-phone {
    background-position: -80px 0;
  }

  &.icon-facebook {
    background-position: -100px 0;
  }

  &.icon-search {
    background-position: -120px 0;
  }

  &.icon-close {
    background-position: -120px -20px;
  }

  &.icon-skills-1,
  &.icon-skills-2,
  &.icon-skills-3,
  &.icon-skills-4 {
    width: 80px;
    height: 60px;
  }

  &.icon-skills-1 {
    background-position: -140px 0;
  }

  &.icon-skills-2 {
    background-position: -220px 0;
  }

  &.icon-skills-3 {
    background-position: -300px 0;
  }

  &.icon-skills-4 {
    background-position: -380px 0;
  }

}