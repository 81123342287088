// Font family
$font-stack: 'Inter',
sans-serif;
// Html

html {
  color: $bk-a;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
}

// Google font ballback

// Title :: soft reset

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.2em;
}

p {
  margin-bottom: 0;
}

.h-like-a {
  font-size: 50px;
  font-weight: 500;
  line-height: 1.1em;
  text-transform: uppercase;
}

.h-like-b {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.35em;
  text-transform: uppercase;
}

.h-like-c {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.1em;
}

@include media-breakpoint-down(lg) {
  .h-like-a {}
}

@include media-breakpoint-down(md) {
  .h-like-a {}

  .h-like-b {
    font-size: 30px;
  }
}

@include media-breakpoint-down(sm) {
  .h-like-a {
    font-size: 40px;
  }

  .h-like-b {
    font-size: 28px;
  }

  .h-like-c {
    font-size: 23px;
  }
}

@include media-breakpoint-down(xs) {
  .h-like-a {}
}

// Paragraph hierarchy :: Bigger to smaller

p {
  margin-bottom: 0;
}

.p-like-a {
  font-size: 12px;
  font-weight: 100;
  line-height: 1.8em;
}

.p-like-b {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.4em;
}

@include media-breakpoint-down(lg) {
  .p-like-a {}
}

@include media-breakpoint-down(md) {}

@include media-breakpoint-down(sm) {}

@include media-breakpoint-down(xs) {
  .p-like-a {
    font-size: 12px;
  }

}